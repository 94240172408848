import * as Yup from 'yup';

export const FORM_FIELDS = {
    bonusRecipients: {
        name: 'bonusRecipients',
        label: 'Who do you want to send bonus to:',
        placeholder: 'Type or select someone',
    },
    bonusAmount: {
        name: 'bonusAmount',
        label: 'Bonus Amount:',
        placeholder: 'Enter the amount you would like to award as bonus',
        type: 'number',
    },
    bonusMessage: {
        name: 'bonusMessage',
        label: 'Message',
        placeholder: 'Write a message to go along with the bonus',
    },
    bonusChannel: {
        name: 'bonusChannel',
        label: 'Where do you want to post the message?',
        placeholder: 'Type or select channel',
    },
};

export const initialValues = {
    bonusRecipients: '',
    bonusAmount: '',
    bonusMessage: '',
    bonusChannel: '',
};
export const getValidationSchema = () =>
    Yup.object().shape({
        bonusRecipients: Yup.object().required('Please complete this required field.'),
        bonusAmount: Yup.number()
            .min(1, 'Bonus amount must be at least 1')
            .max(999999, 'Bonus amount must not exceed 999999')
            .required('Bonus amount is required'),
        bonusMessage: Yup.string()
            .required('Bonus message is required')
            .max(3000, 'Bonus message must be less than or equal to 3000 characters'),
        bonusChannel: Yup.object().required('Bonus channel is required'),
    });
