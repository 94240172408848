import * as Yup from 'yup';

export const SUB_HEADER = "From today, we're going to celebrate our special days together";

export const FORM_FIELDS = {
    birthDate: {
        name: 'birthDate',
        label: 'Please Select your birthdate',
        placeholder: 'Select a date',
        optional: true,
    },
    workAnnivarsaryDate: {
        name: 'workAnnivarsaryDate',
        label: 'Please add your date of joining',
        placeholder: 'Select a date',
        optional: true,
    },
};

export const initialValues = (birthDate, anniversaryDate) => {
    return {
        birthDate: birthDate ? new Date(birthDate) : null,
        workAnnivarsaryDate: anniversaryDate ? new Date(anniversaryDate) : null,
    };
};
export const validationSchema = Yup.object().shape({
    birthDate: Yup.date().nullable(),
    workAnnivarsaryDate: Yup.date().nullable(),
});
