import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import * as microsoftTeams from '@microsoft/teams-js';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { tutorialList } from './constants';
import { ReactComponent as PlayIcon } from 'assets/images/play-icon.svg';
import { ReactComponent as BookADemo } from 'assets/images/book-a-demo.svg';
import './style.scss';

const Index = () => {
    const { search } = useLocation();
    const isAdmin = !!queryString.parse(search)?.isAdmin;
    const [darkTheme, setDarkTheme] = useState(false);
    const [activeTutorial, setActiveTutorial] = useState(Number(!isAdmin) * 2); // calculation to choose the active tutorial

    useEffect(() => {
        microsoftTeams.initialize();
        microsoftTeams.getContext(async (context) => {
            setDarkTheme(context.theme === 'dark');
        });
    }, []);

    return (
        <div className={clsx({ 'tutorial-main-container': true, 'dark-tutorial-main-container': darkTheme })}>
            <div className='tutorial-heading-container text-center'>
                <div className='headline-3 margin-bottom-8'>Welcome to EngageWith</div>
                <div className='body-3'>Send out candid recognitions to your teammates and redeem rewards!</div>
            </div>
            <div className='tutorial-body-container'>
                <div>
                    <div className='headline-2 margin-bottom-16'>Quick tutorials to get you started</div>
                    {tutorialList.map((tutorial, index) =>
                        isAdmin || index > 1 ? (
                            <div key={tutorial.id} className='tutorial-body'>
                                <div
                                    className={clsx({
                                        'tutorial-accordian body-3': true,
                                        'active-accordian': activeTutorial === index,
                                    })}
                                    onClick={() => setActiveTutorial(index)}
                                >
                                    {tutorial.title} {activeTutorial !== index && <PlayIcon />}
                                </div>
                                <img
                                    src={tutorial.video}
                                    alt={tutorial.title}
                                    className={clsx({ 'tutorial-image': true, hidden: activeTutorial !== index })}
                                />
                            </div>
                        ) : null
                    )}
                </div>
                <img
                    src={tutorialList[activeTutorial].video}
                    alt={tutorialList[activeTutorial].title}
                    className='tutorial-image-desktop'
                />
            </div>
            <div className='tutorial-footer-container'>
                <BookADemo />
                <div>
                    <div className='headline-3'>Need help in getting started?</div>
                    <button
                        className='book-a-demo-btn'
                        onClick={() => microsoftTeams.executeDeepLink('https://calendly.com/engagewith-demo/demo')}
                    >
                        Book a demo
                    </button>
                    <div className='or'>OR</div>
                    <div className='body-3 contact-us'>
                        Contact us at{' '}
                        <a onClick={() => microsoftTeams.executeDeepLink('mailto:engagewith@springworks.in')}>
                            engagewith@springworks.in
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
