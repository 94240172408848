import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { enhanceMessage } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';
import { Button, Spinner, typographyStyles, makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
    text: typographyStyles.caption1Stronger,
});

const AIEnhanceButton = ({ reason, setReason, authToken }) => {
    const { setSnackbar } = useToaster();
    const styles = useStyles();
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);
    const [originalRecognitionMessage, setOriginalRecognitionMessage] = useState(reason);
    const [aiEnhancMessage, setAiEnhancMessage] = useState('');

    const { mutateAsync: handleEnhanceMessage, isLoading: isEnhancingMessage } = useMutation(enhanceMessage);

    const handleRevertOriginalRecognitionMessage = () => {
        setReason(originalRecognitionMessage);
        setIsAiEnhanced(false);
    };

    const handleAIEnhanceMessage = async () => {
        try {
            if (originalRecognitionMessage !== reason) {
                const aiEnhancedMessage = await handleEnhanceMessage({ reason, authToken });
                setOriginalRecognitionMessage(reason);
                setAiEnhancMessage(aiEnhancedMessage);
                setReason(aiEnhancedMessage);
                setIsAiEnhanced(true);
            } else {
                setReason(aiEnhancMessage);
                setIsAiEnhanced(true);
            }
        } catch (err) {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
        }
    };
    const handleClick = (e) => {
        e.preventDefault();
        if (isAiEnhanced) {
            handleRevertOriginalRecognitionMessage();
        } else {
            handleAIEnhanceMessage();
        }
    };

    return (
        <Button
            icon={isEnhancingMessage ? <Spinner size='tiny' /> : null}
            className={styles.text}
            size='small'
            onClick={handleClick}
        >
            {isAiEnhanced ? 'Revert to original message' : 'Enhance message with AI'}
        </Button>
    );
};

AIEnhanceButton.propTypes = {
    reason: PropTypes.string.isRequired,
    setReason: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
};

export default AIEnhanceButton;
