import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CelebrationDatesForm from './CelebrationDateForm';
import CustomLoader from 'components/CustomOverlay';
import './style.scss';
import { Subtitle2Stronger } from '@fluentui/react-components';
import clsx from 'clsx';

const Index = () => {
    const [searchParams] = useSearchParams();
    const birthDate = searchParams.get('birthDate');
    const anniversaryDate = searchParams.get('anniversaryDate');
    const [submitLoader, setSubmitLoader] = useState(false);

    return (
        <CustomLoader active={submitLoader}>
            <div className={clsx({ 'celebration-modal': true })}>
                <Subtitle2Stronger>Add Celebrations Dates</Subtitle2Stronger>
                <CelebrationDatesForm
                    birthDate={birthDate === 'undefined' ? null : birthDate}
                    anniversaryDate={anniversaryDate === 'undefined' ? null : anniversaryDate}
                    setSubmitLoader={setSubmitLoader}
                />
            </div>
        </CustomLoader>
    );
};

export default Index;
