import React, { useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/images/back-icon.svg';
import { ReactComponent as CoinIcon } from 'assets/images/coin-icon.svg';
import RecognitionForm from './RecognitionForm';
import CustomLoader from 'components/CustomOverlay';
import { Subtitle2Stronger, Body1Stronger } from '@fluentui/react-components';
import './style.scss';
import clsx from 'clsx';

const Index = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isTab = location.state?.token;
    const token = searchParams.get('token') || location.state?.token;
    const messageId = searchParams.get('messageId');
    const [creditBalance, setCreditBalance] = useState(0);
    const [submitLoader, setSubmitLoader] = useState(false);

    return (
        <CustomLoader active={submitLoader}>
            <div className={clsx({ 'recognize-modal': true })}>
                {isTab && (
                    <div className='back-navigation'>
                        <span onClick={() => navigate(-1)}>
                            <BackIcon /> Back
                        </span>
                    </div>
                )}
                <div className='recognize-modal-heading'>
                    <Subtitle2Stronger>{messageId ? 'Add-on recognition' : 'Recognize Someone'}</Subtitle2Stronger>
                    <div className='recognize-modal-balance'>
                        <Body1Stronger style={{ opacity: '0.7' }}>Current Balance</Body1Stronger>
                        <div className='subtitle-4'>
                            <CoinIcon /> {creditBalance}
                        </div>
                    </div>
                </div>
                <RecognitionForm
                    authToken={token}
                    setCreditBalance={setCreditBalance}
                    creditBalance={creditBalance}
                    setSubmitLoader={setSubmitLoader}
                    messageId={messageId}
                />
            </div>
        </CustomLoader>
    );
};

export default Index;
