import React from 'react';
import { useNavigate } from 'react-router-dom';
import { app } from '@microsoft/teams-js';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Card = ({
    Illustration,
    headline,
    subHeading,
    externalLink = false,
    ExternalLinkIcon,
    subtext,
    link,
    isExternal = false,
    onClick,
}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            if (isExternal) {
                app.openLink(link);
            } else {
                navigate(link);
            }
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <div className='option-container' onClick={handleClick}>
            <Illustration className='illustration-icons' />
            <div>
                <div className={clsx('headline-1', { 'external-link': externalLink })}>
                    {headline}
                    <span>{externalLink ? <ExternalLinkIcon /> : subHeading}</span>
                </div>
                <div className='body-3'>{subtext}</div>
            </div>
        </div>
    );
};

export default Card;

Card.propTypes = {
    Illustration: PropTypes.elementType.isRequired,
    headline: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    externalLink: PropTypes.bool,
    ExternalLinkIcon: PropTypes.elementType,
    subtext: PropTypes.string,
    link: PropTypes.string,
    isExternal: PropTypes.bool,
    onClick: PropTypes.func,
};
