import axios from 'axios';
import jwt_decode from 'jwt-decode';

const apiBaseURL = process.env.REACT_APP_API_URL;

const handleHeaders = (authToken) => {
    let headers = {
        'app-version': '1',
        'content-type': 'application/json',
        'device-type': '3',
    };
    if (authToken) {
        const decode = jwt_decode(authToken);
        headers['Authorization'] = 'Bearer ' + authToken;
        headers['platform'] = decode['platform'];
    }
    return headers;
};

export const getData = (path, authToken, auth = null) => {
    const headers = handleHeaders(authToken);
    return new Promise(function (resolve, reject) {
        axios({
            method: 'get',
            responseType: 'json',
            url: apiBaseURL + path,
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                return reject(error);
            });
    });
};

export const postData = (path, payload, authToken, auth = null) => {
    const headers = handleHeaders(authToken);
    return new Promise(function (resolve, reject) {
        axios({
            method: 'post',
            responseType: 'json',
            url: apiBaseURL + path,
            data: payload,
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                return reject(error);
            });
    });
};
