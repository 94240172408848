import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import clsx from 'clsx';
import Routes from './Routes';
import { SnackbarProvider } from 'context/SnackbarContext';
import { CustomToast } from 'components/CustomToast';
import { useCommonContext } from 'context';
import { FluentProvider } from '@fluentui/react-components';
import { useTeamsTheme } from 'utils/useTeamsTheme';

function App() {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        retry: 2,
                        refetchOnWindowFocus: false,
                    },
                },
            })
    );
    const theme = useTeamsTheme();
    const { darkTheme } = useCommonContext();

    return (
        <FluentProvider theme={theme}>
            <div className={clsx({ App: true, 'dark-App': darkTheme })}>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>
                        <CustomToast />
                        <Routes />
                    </SnackbarProvider>
                </QueryClientProvider>
            </div>
        </FluentProvider>
    );
}

export default App;
