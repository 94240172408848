import React from 'react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { Field as FluentField, makeStyles, typographyStyles } from '@fluentui/react-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        marginBottom: '10px',
    },
    text: typographyStyles.subtitle1,
    optionalText: {
        opacity: 0.5,
        fontFamily: typographyStyles.body1,
    },
});

export const CustomCalendar = ({ name, label, placeholder, disabled, optional = false }) => {
    const styles = useStyles();

    const formatDate = (date) => {
        return date ? format(date, 'MMM dd yyyy') : '';
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue, errors, touched } }) => {
                return (
                    <div className={styles.root}>
                        <FluentField
                            label={
                                <>
                                    {label}
                                    {optional && <span className={styles.optionalText}> (Optional)</span>}
                                </>
                            }
                            className={styles.text}
                            validationMessage={touched[name] && errors[name]}
                        >
                            <DatePicker
                                value={value}
                                onSelectDate={(date) => setFieldValue(name, date)}
                                placeholder={placeholder}
                                className={styles.control}
                                disabled={disabled}
                                formatDate={formatDate}
                                maxDate={new Date()}
                            />
                        </FluentField>
                    </div>
                );
            }}
        </Field>
    );
};

CustomCalendar.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    optional: PropTypes.bool,
};
