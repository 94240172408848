import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';

const Index = ({ active, children }) => {
    return (
        <LoadingOverlay
            active={active}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'transparent',
                }),
            }}
        >
            {children}
        </LoadingOverlay>
    );
};
Index.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.element,
};
export default Index;
