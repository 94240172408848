export const capitalizeFLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};

export const getTeamsChannelName = (channel) => {
    return `${channel.groupName} \u2192 ${channel.channelName}`;
};

export const showSnackBarMessage = (SetSnackbar, variant, message) => {
    SetSnackbar({
        open: true,
        variant,
        message: message,
    });
};
