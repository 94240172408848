import { useState, useEffect } from 'react';
import { app } from '@microsoft/teams-js';
import { teamsLightTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-components';
import { useCommonContext } from 'context';

export const useTeamsTheme = () => {
    const { setDarkTheme } = useCommonContext();
    const [theme, setTheme] = useState(teamsLightTheme); // Default to light theme

    useEffect(() => {
        const initializeApp = async () => {
            await app.initialize();
            const context = await app.getContext();
            updateTheme(context.app.theme);
            app.notifySuccess();
        };

        const updateTheme = (theme) => {
            switch (theme) {
                case 'dark':
                    setTheme(teamsDarkTheme);
                    setDarkTheme(true);
                    break;
                case 'contrast':
                    setTheme(teamsHighContrastTheme);
                    break;
                default:
                    setTheme(teamsLightTheme);
                    setDarkTheme(false);
            }
        };

        initializeApp();

        // Listen for theme changes
        app.registerOnThemeChangeHandler((theme) => {
            updateTheme(theme);
        });
    }, [setDarkTheme]);

    return theme;
};
