import React from 'react';
import { useToaster } from 'context/SnackbarContext';
import { Toast, Toaster, ToastTrigger, Link, ToastTitle, useToastController } from '@fluentui/react-components';

export const CustomToast = () => {
    const { snackbar, setSnackbar } = useToaster();
    const { open, variant, message } = snackbar;
    const toasterId = 'custom-toast-toaster';
    const { dispatchToast } = useToastController(toasterId);

    React.useEffect(() => {
        if (open) {
            dispatchToast(
                <Toast>
                    <ToastTitle
                        action={
                            <ToastTrigger>
                                <Link>Dismiss</Link>
                            </ToastTrigger>
                        }
                    >
                        {message || 'Something went wrong'}
                    </ToastTitle>
                </Toast>,
                { intent: variant, timeout: 8000, onDismiss: () => setSnackbar({ ...snackbar, open: false }) }
            );
        }
    }, [snackbar]);

    return <Toaster toasterId={toasterId} />;
};
