import React from 'react';
import { dialog } from '@microsoft/teams-js';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import { CustomInputField } from 'components/CustomInputField';
import { CustomTextArea } from 'components/CustomTextArea';
import { getValidationSchema, FORM_FIELDS, initialValues } from './constants';
import { useBonusData } from './useBonusData';
import { Caption1 } from '@fluentui/react-components';
import { Formik, Form } from 'formik';
import { EWButton } from 'components/EWButton';
import PropTypes from 'prop-types';

const BonusForm = ({ authToken, setSubmitLoader }) => {
    const { members, channels } = useBonusData(authToken);
    const getBonusAmountLabel = (selectedUser) => {
        if (selectedUser) {
            return `Bonus Amount in ${selectedUser?.currency}:`;
        } else {
            return 'Bonus Amount:';
        }
    };

    const handleSubmit = (values) => {
        setSubmitLoader(true);
        const data = {
            amount: values.bonusAmount,
            selectedUsers: [values.bonusRecipients.id],
            message: values.bonusMessage,
            channel: values.bonusChannel,
            currency: values.bonusRecipients.currency,
        };
        if (values.bonusChannel?.id) {
            data.channel = values.bonusChannel.id;
        }

        dialog.url.submit(
            { data, startedVia: 'EW Shortcuts messaging extension', flagType: 'bonus_submit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values }) => (
                <Form>
                    <CustomSingleSelectDropdown
                        {...FORM_FIELDS.bonusRecipients}
                        isDropdownOptionLoading={members.isFetching}
                        dropdownOption={members.data}
                    />
                    <CustomInputField
                        {...FORM_FIELDS.bonusAmount}
                        label={getBonusAmountLabel(values[FORM_FIELDS.bonusRecipients.name])}
                        s
                    />

                    <CustomTextArea {...FORM_FIELDS.bonusMessage} />
                    <CustomSingleSelectDropdown
                        {...FORM_FIELDS.bonusChannel}
                        isDropdownOptionLoading={channels.isFetching}
                        dropdownOption={channels.data}
                    />
                    <div className='body-2' style={{ marginTop: '-5px' }}>
                        <Caption1> Bonus message can be sent privately or to a public channel. </Caption1>
                    </div>

                    <EWButton appearance='primary' buttonText='Submit' />
                </Form>
            )}
        </Formik>
    );
};
BonusForm.propTypes = {
    authToken: PropTypes.string,
    setSubmitLoader: PropTypes.func,
};

export default BonusForm;
