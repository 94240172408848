import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        variant: 'success',
        message: '',
    });

    return <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>{children}</SnackbarContext.Provider>;
};

SnackbarProvider.propTypes = {
    children: PropTypes.node,
};

export const useToaster = () => useContext(SnackbarContext);
