import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CommonContext = createContext({});
export const CommonProvider = ({ children }) => {
    const [darkTheme, setDarkTheme] = useState(false);
    return <CommonContext.Provider value={{ darkTheme, setDarkTheme }}>{children}</CommonContext.Provider>;
};

CommonProvider.propTypes = {
    children: PropTypes.node,
};

export const useCommonContext = () => {
    return useContext(CommonContext);
};
