export const WELCOME_HEADING = 'Welcome to EngageWith';
export const WELCOME_SUBTEXT = 'Send out candid recognitions to your teammates and redeem rewards!';
export const WHATS_ON_YOUR_MIND = "What's on your mind today?";
export const RECOGNIZE_SOMEONE = 'Recognize Someone';
export const EXTRA_EFFORTS = 'Extra efforts should always be acknowledged!';
export const REDEEM = 'Redeem';
export const VIEW_IN_WEB = '(View in web)';
export const REDEEM_SUBTEXT = "Cha-Ching$ Let's cash in those points";
export const AWARD_BONUS = 'Award a bonus';
export const AWARD_BONUS_SUBTEXT = 'Award your employees on special occasions';
export const RECOGNITION_SETUP_DONE_ADMIN =
    'Please set up Payments and country for points conversion. Clicking on setup will take you to dashboard.';
export const RECOGNITION_SETUP_DONE_USER = 'Please contact your admin to set up Rewards and Recognitions.';
export const REWARDS_AND_RECOGNITION = 'Rewards and Recognition';
export const GO_TO_SETTINGS = 'Go to settings (view in web)';
export const EMPLOYEE_AWARDS = 'Employee Awards';
export const EMPLOYEE_AWARDS_SUBTEXT =
    'Recognize achievements with our <span class="highlighted-text">Direct</span> and <span class="highlighted-text">Poll</span> Awards!';
