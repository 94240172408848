import { useState } from 'react';
import { useQuery } from 'react-query';
import { getBonusData } from 'Services/apiFunctions';
import { GET_BONUS_DATA } from 'Services/apiKeys';
import { useNavigate } from 'react-router-dom';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';

export const useBonusData = (authToken) => {
    const navigate = useNavigate();
    const { setSnackbar } = useToaster();
    const [members, setMembers] = useState({ data: [], isFetching: true });
    const [channels, setChannels] = useState({ data: [], isFetching: true });

    useQuery([GET_BONUS_DATA, authToken], getBonusData, {
        onError: (err) => {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
            navigate('/modals/inactive');
        },
        onSuccess: (data) => {
            let bonusData = data.data;
            let memberList = bonusData.users.reduce((list, member) => {
                return [...list, { header: member.userName, currency: member.userCurrency, id: member._id }];
            }, []);
            let channelList = bonusData.channels.reduce(
                (list, option) => {
                    return [
                        ...list,
                        {
                            header: `${option.groupName} -> ${option.channelName}`,
                            channelId: option.channelId,
                            groupId: option.groupId,
                        },
                    ];
                },
                [{ header: 'Send privately', content: '', id: 'sendPrivately' }]
            );

            setMembers({ data: memberList, isFetching: false });
            setChannels({ data: channelList, isFetching: false });
        },
    });

    return { members, channels };
};
