export const tutorialList = [
    {
        title: 'How to set up Rewards and Recognition?',
        video: require('assets/gifs/EW-gif-setup-rewards.gif'),
        id: 1,
    },
    {
        title: 'How to enable Reward redemption?',
        video: require('assets/gifs/EW-gif-enable-rewards.gif'),
        id: 2,
    },
    {
        title: 'How to recognize?',
        video: require('assets/gifs/EW-gif-how-to-recognize.gif'),
        id: 3,
    },
    {
        title: 'How to redeem?',
        video: require('assets/gifs/EW-gif-how-to-redeem.gif'),
        id: 4,
    },
];
