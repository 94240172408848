import React from 'react';
import { ReactComponent as InactiveIllustration } from 'assets/images/inactive-illustration.svg';
import './style.scss';

const Index = () => {
    return (
        <div className='inactive-main-container'>
            <div className='headline-3'>Welcome to EngageWith</div>
            <div className='body-3'>
                Big wins or small, celebrate them all! Send out candid recognitions to your teammates and redeem
                rewards!
            </div>
            <InactiveIllustration />
            <div className='headline-1'>You are currently an inactive user, please contact your admin.</div>
        </div>
    );
};

export default Index;
