import React from 'react';
import { Field } from 'formik';
import { Field as FluentField, Textarea, typographyStyles, makeStyles } from '@fluentui/react-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: typographyStyles.subtitle2,
});

export const CustomTextArea = ({ name, label, placeholder }) => {
    const styles = useStyles();
    return (
        <Field name={name} size='large'>
            {({ field, form: { setFieldValue, setFieldTouched, errors, touched } }) => {
                const onChange = (ev) => {
                    const { value } = ev.target;
                    setFieldValue(name, value);
                };
                return (
                    <FluentField
                        label={label}
                        className={styles.text}
                        style={{ marginBottom: '10px' }}
                        validationMessage={touched[name] && errors[name]}
                    >
                        <Textarea
                            value={field.value}
                            onChange={onChange}
                            appearance='outline'
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            placeholder={placeholder}
                            resize='both'
                        />
                    </FluentField>
                );
            }}
        </Field>
    );
};

CustomTextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.string,
};
